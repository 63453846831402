.Luxwelcome {
  position: relative;
  width: 100%;
  direction: rtl;
  }

.Luxwelcome-overlay {
  direction: rtl;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('https://storage.googleapis.com/djangobucket2023/photos/suite/711A8109.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Luxwelcome-text {
  opacity: 1;
  text-align: center;
  color: #000000;
  padding: 20px;
  transition: opacity 1s;
  direction: rtl;
}

.Luxwelcome-text.visible {
  opacity: 1;
}

.Luxwelcome-text h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(0, 0, 0);
  margin-top: 70px;
}
.Luxwelcome-text p{
  color: rgb(0, 0, 0);
}
.Luxwelcome-text p {
  font-size: 1.5rem;
  font-weight: 300;
  max-width: 1000px;
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}
/* Show floating button only on small screens */
@media screen and (max-width: 768px) {
  .Luxwelcome-text h1 {
    font-size: 2rem;
  }
}