html, body
{
    height: 100%;
}
.ChatApp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
  font-family: 'Arial', sans-serif;
}

.chat-container {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  width: 400px;
height: 90%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.chat-messages {
direction: rtl;
    height: calc(100% - 140px);
    overflow-y: auto;
  margin-bottom: 20px;
        display: flex;
    flex-direction: column;
}

.chat-messages p {
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  background-color: #f1f1f1;
  max-width: 80%;
  display: inline-block;
}

.chat-messages p strong {
  font-weight: bold;
}

input,
button {
  font-family: 'Arial', sans-serif;
}

input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 44px 12px 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2em;
  resize: vertical;
  overflow: auto;
}
input:focus + button {
  background-color: darkgreen;
}
.chat-container {
  position: relative;
}
textarea {
  font-size: 120%;
  font-family: 'Arial', sans-serif;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: calc(100% + 20px);
}
.user-message {
  color: white;
margin-bottom: 5px !important;
    background-color: green !important;
      align-self: flex-start;
}

.chatgpt-message {
  color:midnightblue;
    margin-bottom: 5px !important;
    align-self: flex-end;
}
textarea{
    height: auto;
}
.send-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.send-container button {
  order: -1;
  margin-right: 4px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 120%;
width: 42px;
    height: 38px;
        background-size: 36px 36px;
    background-position: 1px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
    
}

.send-container button:hover {
  background-color: darkgreen;
}

.send-container textarea {
  font-family: 'Arial', sans-serif;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: calc(100% + 20px);
    direction: rtl;
}
h4 {
    margin: 5px !important;
    color: gray;
}
h2 {
    margin: 5px !important;
}
@media (max-width: 500px) {
  input {
    font-size: 1.1em;
  }

  .chat-container button {
    width: 321px;
    height: 32px;
    font-size: 1.1em;
  }

  .chat-container button:before {
    margin-right: 4px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid white;
  }
}
