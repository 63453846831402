/* FeatureGrid.css */



.feature {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  
}
.feature-grid .feature{
  box-shadow: none;
}
.feature-grid .icon {
  font-size: 24px;
}
.icon {
  margin-right: 10px;
}

.name {
  font-size: 16px;
  color: #333;
}
.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 40px 11%;
  justify-content: flex-start;
  direction: rtl;
  }
  .separator {
    vertical-align: middle;
    width: 200px;
    padding-bottom: 30px;
}
.icon-text {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px; /* Add some space between the icon and the text */
}

@media (max-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr;
  }
}
