.introduction-container {
    text-align: center;
    padding: 30px;
    /* background-color: #f9f9f9;
    border: 1px solid #e0e0e0; */
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    margin: 0 auto;
}
.introduction-content {
    text-align: center;
  }

.introduction-header {
    font-size: 32px;
    margin-bottom: 20px;
}

.introduction-text {
    font-size: 21px;
    margin-bottom: 0;
}

.introduction-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
}
