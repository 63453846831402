/* NavigationPage.css */
/* NavigationPage.css */

.navigation-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1000;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

}

.navigation-page.open {
  transform: translateX(0);
}

.nav-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.hotel-icon {
  color: #2c3e50;
}

.close-icon {
  cursor: pointer;
  color: #2c3e50;
  position: absolute;
  right: 15px;
  top: 15px;
}

.nav-links {
  display: grid; /* use grid layout */
  grid-template-columns: 1fr 1.7fr;
  gap: 10px; /* spacing between grid items */
  margin-bottom: 20px;
}
.small-links {
  display: grid; /* use grid layout */
  grid-template-columns: 1fr 1.7fr;
  gap: 5px; /* spacing between grid items */
  margin-bottom: 20px;
}

.nav-links a, .small-links a {
  color: #2c3e50;
  text-decoration: none;
  margin: 10px 0;
  font-size: 21px;
  padding: 5px;
  transition: background-color 0.3s ease-in-out;
}


.nav-links a:hover {
  background-color: #f2f2f2;
}

.small-links a {
  font-size: 16px;
}

.hotel-icon{
  color: #63b9f0;
}
.logo-nav-page{
  width: 170px;
  margin-top: 50px;
}
.phone{  
  font-size: 22px;
}