.negtext{
  margin:10px;
  margin-top:50px;
}
.termofservice {
  text-align: right;
  margin: 50px 5%;
}
.termofservice .tosh3 {
font-size: 22px;
}
img.kosher-img {
  width: 100%;
  max-width: 800px;
}
.resttable{
  display: inline-block;
}

/* CSS */
.table-container {
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.MsoNormal{
  padding: 30px;
}
@media (max-width: 769px) {
  .responsive-table {
      font-size: 14px;;
  }
  .table-container td:nth-child(1) {
    min-width: 180px;

  }
}