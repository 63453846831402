/* NavigationBar.css */

.nav-bar {
  position: sticky;
  top: 0;
  z-index: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 20px;
  background-color: #fff;
  color: #333;
  transition: padding 0.2s ease-in-out;
  border-bottom: 1px solid #e4e4eb;
  box-shadow: 1px 1px 17px #8080808a;
  height: 55px;
  transition: background-color 0.3s ease-in-out;
}
.nav-bar .phone-bar {
  font-size: 18px;
  text-decoration: none;
  position: absolute;
  left: 5%;
}
img.logoclass {
  max-height: 40px;
  margin-top: -4px;
  margin-left: 0;
  transition: max-height 0.2s ease-in-out;
}
img.logoclass.shrink{
  max-height: 35px;
  margin-top: -2px;
  margin-left: 0;
}
.nav-bar.shrink {
  padding: 0px 20px; /* Adjust these values as needed */
}
.desktop-menu li.active a {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}
.desktop-menu {
  display: none;
  list-style: none;
  padding-left: 0;
}

.desktop-menu li {
  display: inline-block;
  margin-right: 20px;
}

.desktop-menu li a {
  color: #333;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
}

.desktop-menu li a:hover {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.desktop-menu li a:active {
  background-color: #007bff;
  color: #fff;
}

.hamburger-icon, .menu-icon {
  cursor: pointer;
}
.hamburger-icon {
  cursor: pointer;
  padding-top: 8px;
  font-size: 38px;
}

.title {
  font-size: 24px;
}
.floating-button-desk,  .resDeskBut {
  display: none;
}
/* For tablets and desktops */
@media screen and (min-width: 768px) {
  .hamburger-icon {
      display: none;
  }

  .desktop-menu {
      display: block;
  }
  nav.nav-bar {
    direction: rtl;
    justify-content: flex-start;

  }
  .resDeskBut{
    display: block !important;
    background-color: white;
    width: 200px !important;
    position: static !important;
    height: 60px;
    box-shadow: 1px 1px 1px 7px rgba(15, 17, 23, 0.1);
    border-radius: 10px 10px 0px 0px;
    z-index: 1000;
    display: block;
  }
  .floating-button-desk {
    display: block;
    position: relative;
    width: 200px;
    height: 47px;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    z-index: 1000;
    line-height: 18px;
    font-weight: 200;
    cursor: pointer;
  
  }
  .arrowlong{
    bottom: -50px;
    content: "";
    position: absolute;
    right: 122px;
    transform: rotate(90deg);
    transition: right .25s ease;
    height: 113px;
    width: 6px;
  }
  button.floating-button-desk:hover img {
    right: 135px;
}
}
@media screen and (max-width: 768px) {
  .nav-bar .phone-bar {
  display: none;
  }
}