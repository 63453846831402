@font-face {
    font-family: 'Heebo-Regular';
    src: local('Heebo-Regular'), url('../assets/fonts/Heebo-Regular.ttf') format('truetype');
}
body {
    font-family: 'Heebo-Regular', sans-serif;
}
.scroll-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  h1,p,h2,h3,h4,h5,h6,span {
    color: #646464;
    direction: rtl;
  }


  #arrow-container {
    position: fixed;
    bottom: 100px;
    right: 30px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
  }
  ul{direction: rtl;}
  .arrows {
    width: 60px;
    height: 72px;
  }
  
  .arrows path {
    stroke: #ffffff;
    fill: transparent;
    stroke-width: 1px;	
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite; 
  }
  
  @keyframes arrow
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  @-webkit-keyframes arrow /*Safari and Chrome*/
  {
  0% {opacity:0}
  40% {opacity:1}
  80% {opacity:0}
  100% {opacity:0}
  }
  
  .arrows path.a1 {
    animation-delay:-1s;
    -webkit-animation-delay:-1s; /* Safari 和 Chrome */
  }
  
  .arrows path.a2 {
    animation-delay:-0.5s;
    -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
  }
  
  .arrows path.a3 {	
    animation-delay:0s;
    -webkit-animation-delay:0s; /* Safari 和 Chrome */
  }
  


  
  