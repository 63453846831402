@font-face {
  font-family: 'Heebo-Regular';
  src: local('Heebo-Regular'), url('../assets/fonts/Heebo-Regular.ttf') format('truetype');
}
body {
  font-family: 'Heebo-Regular', sans-serif;
}
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
h1,p,h2,h3,h4,h5,h6,span {
  color: #646464;
  direction: rtl;
}
ul{direction: rtl;}
.luxsuitesapp nav.nav-bar {
  position: fixed;
  width: 100%;
}
.responsive-iframe {
  position: absolute;
  top: 66px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.iframe-container {
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.container2 {
  width: 100% !important;
  padding-top: 80px;
  height: calc(100% - 80px);
  margin-top: 80px;
}


